import { defineStore } from 'pinia';
import { computed, ref } from 'vue';
import { useI18n } from 'vue-i18n';
import { useApi } from '@/composables/useApi';

export const useMonitoringGeneralStore = defineStore('monitoringGeneralStore', () => {
  const { t } = useI18n();
  const { apiGet } = useApi();

  const loading = ref(false);
  const lastUpdated = ref(null);
  const devices = ref([]);
  const currentDevice = ref();

  const config = ref({
    home: {
      label: t('monitoring.home'),
      color: 'home',
    },
    grid: {
      label: t('monitoring.grid'),
      color: 'grid',
      icon: 'mdi-transmission-tower',
    },
    ev: {
      label: t('monitoring.ev'),
      color: 'ev',
      icon: 'mdi-ev-station',
    },
    solar: {
      label: t('monitoring.solar'),
      color: 'solar',
      icon: 'mdi-solar-power-variant-outline',
    },
    battery: {
      label: t('monitoring.charge'),
      color: 'battery',
      icon: 'mdi-battery-medium',
    },
    batteryCharge: {
      label: t('monitoring.charge'),
      color: 'charge',
    },
    batteryDischarge: {
      label: t('monitoring.discharge'),
      color: 'discharge',
    },
    gridConsume: {
      label: t('monitoring.consume'),
      color: 'consume',
    },
    gridInject: {
      label: t('monitoring.inject'),
      color: 'inject',
    },

    insights: {
      earnings: {
        label: t('monitoring.earnings'),
        color: 'earnings',
        icon: 'mdi-home-lightning-bolt-outline',
      },

      savings: {
        label: t('monitoring.savings'),
        color: 'savings',
        icon: 'mdi-scale-balance',

        solar: {
          label: t('monitoring.solar'),
          color: 'solarControl',
        },
        battery: {
          label: t('monitoring.battery'),
          color: 'batteryControl',
        },
        tariff: {
          label: t('monitoring.tariff'),
          color: 'tariffChoice',
        },
        ev: {
          label: t('monitoring.ev'),
          color: 'evControl',
        },
        energy: {
          label: t('monitoring.energy'),
          color: 'energyControl',
        },
      },
    },
  });

  const monitoringEnabled = computed(() => devices.value?.length > 0);
  const currentDeviceId = computed(() => currentDevice.value?.deviceId);

  const getDevices = async () => {
    // no need to check for new devices more than once every 30 minutes
    if (loading.value || (lastUpdated.value && lastUpdated.value < Date.now() - 30 * 60 * 1000)) {
      return;
    }

    loading.value = true;

    const result = await apiGet('monitoring/getDevices');

    devices.value = result;

    if (!currentDevice.value) {
      selectDevice(devices.value[0]);
    }

    lastUpdated.value = Date.now();
    loading.value = false;

    return result;
  };

  const selectDevice = (device) => {
    currentDevice.value = device;
  };

  return {
    loading,
    monitoringEnabled,
    devices,
    currentDevice,
    currentDeviceId,
    config,
    getDevices,
    selectDevice,
  };
});
