<template>
  <!--
        On mobile, user is logged in
        Left button, app bar title, right button
    -->
  <v-app-bar v-if="mobile" id="navbartop" :elevation="0" class="d-flex align-content-space-evenly px-2">
    <!-- Left Side -->
    <template v-if="leftNav == 'back'" #prepend>
      <v-btn
        size="x-small"
        variant="tonal"
        icon="mdi-arrow-left"
        @click="backFunction"
      />
    </template>
    <template v-else-if="leftNav == 'close'" #prepend>
      <v-btn
        size="x-small"
        variant="tonal"
        icon="mdi-close"
      />
    </template>
    <template v-else-if="leftNav == 'profile'" #prepend>
      <v-menu>
        <template #activator="{props}">
          <v-btn
            v-bind="props"
            size="x-small"
            variant="tonal"
            icon="mdi-account-circle-outline"
          />
        </template>
        <v-list>
          <v-list-item v-ripple @click="router.push({ name: 'account' })">
            <v-list-item-title>{{ $t('bottomNav.account') }}</v-list-item-title>
          </v-list-item>
          <v-divider />
          <v-list-item v-ripple @click="logout()">
            <v-list-item-title>{{ $t('logout') }}</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
    </template>

    <!-- Title -->
    <v-app-bar-title>
      <p class="small-text font-weight-medium">
        {{ navTitle }}
      </p>
    </v-app-bar-title>

    <!-- Right side -->
    <template v-if="rightNav == 'home'" #append>
      <div class="pr-1">
        <v-btn
          size="x-small"
          variant="tonal"
          icon="mdi-gift-outline"
          @click="router.push({ name: 'loyalty', state: { backPath: router.currentRoute.value.path } })"
        />
      </div>
      <div>
        <v-badge
          v-model="hasNewNotifications"
          dot
          color="primary"
          location="top start"
        >
          <v-btn
            size="x-small"
            variant="tonal"
            icon="mdi-bell-outline"
            @click="router.push({ name: 'notifications', state: { backPath: router.currentRoute.value.path } })"
          />
        </v-badge>
      </div>
    </template>
    <template v-else-if="rightNav == 'options-close'" #append>
      <!--
            <div class="pr-1">
                <v-btn
                size="x-small"
                variant="tonal"
                icon="mdi-dots-vertical"
                ></v-btn>
            </div>
            -->
      <div>
        <v-btn
          size="x-small"
          variant="tonal"
          icon="mdi-close"
        />
      </div>
    </template>
    <template v-else-if="rightNav == 'options'" #append>
      <!--
            <v-btn
                size="x-small"
                variant="tonal"
                icon="mdi-dots-vertical"
            ></v-btn>
            -->
    </template>
    <template v-else-if="rightNav == 'share-download'" #append>
      <!--
            <div class="pr-1">
                <v-btn
                size="x-small"
                variant="tonal"
                icon="mdi-share-variant-outline"
                ></v-btn>
            </div>
            -->
      <div>
        <v-btn
          size="x-small"
          variant="tonal"
          icon="mdi-tray-arrow-down"
          :loading="downloadDocument.downloading"
          @click="() => download(downloadDocument)"
        >
          <template #loader>
            <v-progress-circular color="base800" size="20" width="1" indeterminate />
          </template>
        </v-btn>
      </div>
    </template>
    <template v-else-if="rightNav == 'download'" #append>
      <v-btn
        size="x-small"
        variant="tonal"
        icon="mdi-tray-arrow-down"
        :loading="downloadDocument.downloading"
        @click="() => download(downloadDocument)"
      >
        <template #loader>
          <v-progress-circular color="base800" size="20" width="1" indeterminate />
        </template>
      </v-btn>
    </template>
    <template v-else-if="rightNav === 'loyalty'" #append>
      <v-btn
        size="x-small"
        variant="tonal"
        icon="mdi-tray-gift-outline"
        @click="router.push({ name: 'loyalty', state: { backPath: router.currentRoute.value.path } })"
      />
    </template>
    <template v-else-if="rightNav === 'monitoring'" #append>
      <select-monitoring-device />
    </template>
  </v-app-bar>

  <!--
        Not on mobile, user is not logged in
        Displays an image as the title
    -->
  <v-app-bar
    v-if="!mobile && !route.meta.requiresAuth"
    id="navbartop"
    :elevation="0"
    class="d-flex align-content-space-evenly px-2"
    color="secondary"
  >
    <v-app-bar-title class="pl-10">
      <logo />
    </v-app-bar-title>
  </v-app-bar>

  <!--
        Not on mobile, user is logged in
        Bread crumb navigation and button on the right
    -->
  <v-app-bar
    v-if="!mobile"
    id="navbartop"
    :elevation="0"
    height="110"
  >
    <div class="d-flex justify-center align-center pt-16 w-100">
      <div style="min-width: 650px" class="d-flex justify-space-between">
        <div class="d-flex justify-start mx-0 flex-1-1-0">
          <template v-if="leftNav == 'back'">
            <v-btn
              size="small"
              variant="tonal"
              icon="mdi-arrow-left"
              @click="backFunction"
            />
          </template>
        </div>
        <div class="d-flex justify-center">
          <div v-if="!isRootPage">
            <p class="small-text font-weight-medium">
              {{ navTitle }}
            </p>
          </div>
        </div>
        <div class="d-flex justify-end mx-0 flex-1-1-0">
          <template v-if="rightNav == 'home'">
            <!--
                      <div class="pr-1">
                          <v-btn
                          size="x-small"
                          variant="tonal"
                          icon="mdi-gift-outline"
                          ></v-btn>
                      </div>
                      <div>
                          <v-btn
                          size="x-small"
                          variant="tonal"
                          icon="mdi-bell-outline"
                          ></v-btn>
                      </div>
                      -->
          </template>
          <template v-else-if="rightNav == 'options-close'">
            <!--
                      <div class="pr-1">
                          <v-btn
                          size="x-small"
                          variant="tonal"
                          icon="mdi-dots-vertical"
                          ></v-btn>
                      </div>
                      -->
            <div>
              <v-btn
                size="x-small"
                variant="tonal"
                icon="mdi-close"
              />
            </div>
          </template>
          <template v-else-if="rightNav == 'options'">
            <!--
                      <v-btn
                          size="x-small"
                          variant="tonal"
                          icon="mdi-dots-vertical"
                      ></v-btn>
                      -->
          </template>
          <template v-else-if="rightNav == 'share-download'">
            <!--
                      <div class="pr-1">
                          <v-btn
                          size="x-small"
                          variant="tonal"
                          icon="mdi-share-variant-outline"
                          ></v-btn>
                      </div>
                      -->
            <div>
              <v-btn
                size="small"
                variant="tonal"
                icon="mdi-tray-arrow-down"
                :loading="downloadDocument.downloading"
                @click="() => download(downloadDocument)"
              >
                <template #loader>
                  <v-progress-circular
                    color="base800"
                    size="20"
                    width="1"
                    indeterminate
                  />
                </template>
              </v-btn>
            </div>
          </template>
          <template v-else-if="rightNav == 'download'">
            <v-btn
              size="small"
              variant="tonal"
              icon="mdi-tray-arrow-down"
              :loading="downloadDocument.downloading"
              @click="() => download(downloadDocument)"
            >
              <template #loader>
                <v-progress-circular
                  color="base800"
                  size="20"
                  width="1"
                  indeterminate
                />
              </template>
            </v-btn>
          </template>
          <template v-else-if="rightNav === 'loyalty'">
            <v-btn
              size="x-small"
              variant="tonal"
              icon="mdi-gift-outline"
              @click="router.push({ path: '/loyalty' })"
            />
          </template>
          <template v-else-if="rightNav === 'monitoring'">
            <select-monitoring-device />
          </template>
        </div>
      </div>
    </div>
  </v-app-bar>
</template>

<script setup>
import Logo from '@components/PortalLogo';
import router from '@/router';
import { useTopNavBarStore } from '@store/topNavBar';
import { storeToRefs } from 'pinia';
import { useUserStore } from '@/store/user';
import { ref, watch } from 'vue';
import { useRoute } from 'vue-router';
import { useDisplay } from 'vuetify';
import { useNotificationStore } from '@/store/notifications';
import { useInstallationDocuments } from '@/composables/useInstallationDocuments';
import SelectMonitoringDevice from '@/components/navbartop/SelectMonitoringDevice.vue';

const { mobile } = useDisplay();
const route = useRoute();

const isRootPage = ref(true);

const store = useTopNavBarStore();
const {
  leftNav,
  navTitle,
  rightNav,
  downloadDocument,
  backFunction,
} = storeToRefs(store);
const {
  download,
} = useInstallationDocuments();

const userStore = useUserStore();
const { logoutUser } = userStore;

const { hasNewNotifications } = storeToRefs(useNotificationStore());

watch(
  () => route.fullPath,
  () => {
    const routeParts = route.fullPath.split('/');
    isRootPage.value = routeParts.length === 2;
  },
  { immediate: true },
);

function logout() {
  logoutUser();
}
</script>

<style scoped>
.small-text {
    font-size: 1.1rem !important;
}
</style>
