export const routes = [
  {
    path: '/',
    name: '/',
    component: () => import('/home/runner/work/portal/portal/src/pages/index.vue'),
    /* no children */
    meta: {
      "requiresAuth": false
    }
  },
  {
    path: '/:404(.*)',
    name: '/[...404]',
    component: () => import('/home/runner/work/portal/portal/src/pages/[...404].vue'),
    /* no children */
    meta: {
      "requiresAuth": false
    }
  },
  {
    path: '/account',
    name: 'account',
    component: () => import('/home/runner/work/portal/portal/src/pages/account.vue'),
    /* no children */
    meta: {
      "requiresAuth": true
    }
  },
  {
    path: '/aftersale',
    name: '/aftersale',
    component: () => import('/home/runner/work/portal/portal/src/pages/aftersale.vue'),
    /* no children */
    meta: {
      "requiresAuth": true
    }
  },
  {
    path: '/confirmregistration',
    name: '/confirmregistration',
    component: () => import('/home/runner/work/portal/portal/src/pages/confirmregistration.vue'),
    /* no children */
    meta: {
      "requiresAuth": false
    }
  },
  {
    path: '/dashboard',
    name: '/dashboard',
    component: () => import('/home/runner/work/portal/portal/src/pages/dashboard.vue'),
    /* no children */
    meta: {
      "requiresAuth": true
    }
  },
  {
    path: '/installations',
    /* internal name: '/installations' */
    /* no component */
    children: [
      {
        path: '',
        name: 'installations',
        component: () => import('/home/runner/work/portal/portal/src/pages/installations/index.vue'),
        /* no children */
        meta: {
          "layout": "AppLayout",
          "requiresAuth": true
        }
      },
      {
        path: ':installation_id',
        name: 'installation',
        component: () => import('/home/runner/work/portal/portal/src/pages/installations/[installation_id].vue'),
        /* no children */
        meta: {
          "requiresAuth": true
        }
      },
      {
        path: 'deliverydetail',
        /* internal name: '/installations/deliverydetail' */
        /* no component */
        children: [
          {
            path: ':id',
            name: 'deliveryDetail',
            component: () => import('/home/runner/work/portal/portal/src/pages/installations/deliverydetail/[id].vue'),
            /* no children */
            meta: {
              "requiresAuth": true
            }
          },
          {
            path: 'order',
            /* internal name: '/installations/deliverydetail/order' */
            /* no component */
            children: [
              {
                path: ':id',
                name: 'deliveryDetailPlanning',
                component: () => import('/home/runner/work/portal/portal/src/pages/installations/deliverydetail/order/[id].vue'),
                /* no children */
                meta: {
                  "requiresAuth": true
                }
              }
            ],
          }
        ],
      },
      {
        path: 'documents',
        /* internal name: '/installations/documents' */
        /* no component */
        children: [
          {
            path: ':installation_id',
            name: 'documents',
            component: () => import('/home/runner/work/portal/portal/src/pages/installations/documents/[installation_id].vue'),
            /* no children */
            meta: {
              "requiresAuth": true
            }
          }
        ],
      },
      {
        path: 'ichoosr',
        /* internal name: '/installations/ichoosr' */
        /* no component */
        children: [
          {
            path: ':id',
            name: 'ichoosr',
            component: () => import('/home/runner/work/portal/portal/src/pages/installations/ichoosr/[id].vue'),
            /* no children */
            meta: {
              "requiresAuth": true
            }
          }
        ],
      },
      {
        path: 'inspectiondetail',
        /* internal name: '/installations/inspectiondetail' */
        /* no component */
        children: [
          {
            path: ':id()_:source',
            name: 'inspectionDetail',
            component: () => import('/home/runner/work/portal/portal/src/pages/installations/inspectiondetail/[id]_[source].vue'),
            /* no children */
            meta: {
              "requiresAuth": true
            }
          }
        ],
      },
      {
        path: 'invoicedetail',
        /* internal name: '/installations/invoicedetail' */
        /* no component */
        children: [
          {
            path: ':id',
            name: 'invoiceDetail',
            component: () => import('/home/runner/work/portal/portal/src/pages/installations/invoicedetail/[id].vue'),
            /* no children */
            meta: {
              "requiresAuth": true
            }
          },
          {
            path: 'list',
            /* internal name: '/installations/invoicedetail/list' */
            /* no component */
            children: [
              {
                path: ':id',
                name: 'invoiceDetailList',
                component: () => import('/home/runner/work/portal/portal/src/pages/installations/invoicedetail/list/[id].vue'),
                /* no children */
                meta: {
                  "requiresAuth": true
                }
              }
            ],
          }
        ],
      },
      {
        path: 'netoperator',
        /* internal name: '/installations/netoperator' */
        /* no component */
        children: [
          {
            path: ':installation_id',
            name: 'netoperator',
            component: () => import('/home/runner/work/portal/portal/src/pages/installations/netoperator/[installation_id].vue'),
            /* no children */
            meta: {
              "requiresAuth": true
            }
          }
        ],
      },
      {
        path: 'offerdetail',
        /* internal name: '/installations/offerdetail' */
        /* no component */
        children: [
          {
            path: ':id()_:version',
            name: 'offerDetail',
            component: () => import('/home/runner/work/portal/portal/src/pages/installations/offerdetail/[id]_[version].vue'),
            /* no children */
            meta: {
              "requiresAuth": true
            }
          },
          {
            path: 'list',
            /* internal name: '/installations/offerdetail/list' */
            /* no component */
            children: [
              {
                path: ':id',
                name: 'offerDetailList',
                component: () => import('/home/runner/work/portal/portal/src/pages/installations/offerdetail/list/[id].vue'),
                /* no children */
                meta: {
                  "requiresAuth": true
                }
              }
            ],
          },
          {
            path: 'upsell',
            /* internal name: '/installations/offerdetail/upsell' */
            /* no component */
            children: [
              {
                path: ':id()_:version',
                name: 'offerUpsell',
                component: () => import('/home/runner/work/portal/portal/src/pages/installations/offerdetail/upsell/[id]_[version].vue'),
                /* no children */
                meta: {
                  "requiresAuth": true
                }
              }
            ],
          }
        ],
      },
      {
        path: 'orderdetail',
        /* internal name: '/installations/orderdetail' */
        /* no component */
        children: [
          {
            path: ':id',
            name: 'orderDetail',
            component: () => import('/home/runner/work/portal/portal/src/pages/installations/orderdetail/[id].vue'),
            /* no children */
            meta: {
              "requiresAuth": true
            }
          }
        ],
      },
      {
        path: 'repairs',
        /* internal name: '/installations/repairs' */
        /* no component */
        children: [
          {
            path: ':repairs_id',
            name: 'repair',
            component: () => import('/home/runner/work/portal/portal/src/pages/installations/repairs/[repairs_id].vue'),
            /* no children */
            meta: {
              "requiresAuth": true
            }
          },
          {
            path: 'list',
            /* internal name: '/installations/repairs/list' */
            /* no component */
            children: [
              {
                path: ':installation_id',
                name: 'repairsList',
                component: () => import('/home/runner/work/portal/portal/src/pages/installations/repairs/list/[installation_id].vue'),
                /* no children */
                meta: {
                  "requiresAuth": true
                }
              }
            ],
          }
        ],
      },
      {
        path: 'survey',
        /* internal name: '/installations/survey' */
        /* no component */
        children: [
          {
            path: ':order_id',
            name: 'installations_survey',
            component: () => import('/home/runner/work/portal/portal/src/pages/installations/survey/[order_id].vue'),
            /* no children */
            meta: {
              "requiresAuth": true
            }
          }
        ],
      }
    ],
  },
  {
    path: '/login',
    name: '/login',
    component: () => import('/home/runner/work/portal/portal/src/pages/login.vue'),
    /* no children */
    meta: {
      "requiresAuth": false
    }
  },
  {
    path: '/loyalty',
    /* internal name: '/loyalty' */
    /* no component */
    children: [
      {
        path: '',
        name: 'loyalty',
        component: () => import('/home/runner/work/portal/portal/src/pages/loyalty/index.vue'),
        /* no children */
        meta: {
          "requiresAuth": true
        }
      }
    ],
  },
  {
    path: '/monitoring',
    /* internal name: '/monitoring' */
    /* no component */
    children: [
      {
        path: 'feed',
        name: 'monitoring',
        component: () => import('/home/runner/work/portal/portal/src/pages/monitoring/feed.vue'),
        /* no children */
        meta: {
          "layout": "AppLayout",
          "requiresAuth": true
        }
      },
      {
        path: 'history',
        name: 'history',
        component: () => import('/home/runner/work/portal/portal/src/pages/monitoring/history.vue'),
        /* no children */
        meta: {
          "layout": "AppLayout",
          "requiresAuth": true
        }
      },
      {
        path: 'insights',
        name: 'insights',
        component: () => import('/home/runner/work/portal/portal/src/pages/monitoring/insights.vue'),
        /* no children */
        meta: {
          "layout": "AppLayout",
          "requiresAuth": true
        }
      }
    ],
  },
  {
    path: '/notifications',
    name: 'notifications',
    component: () => import('/home/runner/work/portal/portal/src/pages/notifications.vue'),
    /* no children */
    meta: {
      "requiresAuth": true
    }
  },
  {
    path: '/support',
    /* internal name: '/support' */
    /* no component */
    children: [
      {
        path: '',
        name: 'support',
        component: () => import('/home/runner/work/portal/portal/src/pages/support/index.vue'),
        /* no children */
        meta: {
          "requiresAuth": true
        }
      },
      {
        path: ':issue_id',
        name: 'issueDetail',
        component: () => import('/home/runner/work/portal/portal/src/pages/support/[issue_id].vue'),
        /* no children */
        meta: {
          "requiresAuth": true
        }
      },
      {
        path: 'createissue',
        name: 'issueCreate',
        component: () => import('/home/runner/work/portal/portal/src/pages/support/createissue.vue'),
        /* no children */
        meta: {
          "requiresAuth": true
        }
      }
    ],
  }
]
